/******************* hmongabc - base and header styles *******************/	
	#top {
    margin: 0 0 20px;
    padding: 50px 0 0;
    position: relative;
    z-index: 100;
}
	
	#top-menu {
    background-color: #CC0000;
    border-bottom: none;
    border-top: none;
    margin: 5px 0 0 -10px;
    padding: 8px 0 5px;
}

	#top-menu ul {
    margin: auto;
    width: 980px;
}
	
	#top-menu a {
    border-right: 1px dotted #DDDDDD;
    color: #FFF;
    display: block;
    font: 24px BebasNeueRegular,arial,sans-serif;
    padding: 4px 10px;
    text-decoration: none;
}

	#top-menu a:hover,
	#top-menu .active a{
	color: #d9cf70;
	background: none;
  } 
	
	#base-nav {
    margin: -98px 648px 0;
    position: absolute;
    width: 400px;
	}

	#base-nav a {
    border-right: 1px dotted #DDDDDD;
    color: #666666;
    display: block;
    font: 24px BebasNeueRegular,arial,sans-serif;
    padding: 4px 10px;
    text-decoration: none;
	}
	
	#base-nav a.last {
    border-right: none;
	}
	
	#base-nav li {
    float: left;
	}

	#header-search {
    width: 215px;
	position:absolute;
	float: left;
    margin: -53px 0 0 270px;
	}
	
	.widget h3 {
    border-bottom: 1px dotted #CCCCCC;
    color: #333333;
    font: 24px BebasNeueRegular,arial,sans-serif;
    margin: 0 0 10px;
}
/*#carousel .browse:hover{ background: none repeat scroll 0 0 #DDDDDD; }*/


/******************* hmongabc - collections - sub categories *******************/
.clearfix:before, .clearfix:after { content: ""; display: table; }
.clearfix:after { clear: both; }
.clearfix { zoom: 1; }

/* Subnavigation styles */
.subnav { clear: both; list-style-type: none; margin: 0 0 20px 0; padding: 0; }
.subnav li { display: block; float: left; }
.subnav li a { 
    display: block; 
    height: 28px; 
    line-height: 28px; 
    padding: 0 7px; 
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
    background: #eee;
    margin: 0 7px 7px 0;
    color: #666;
}
.subnav li a:hover, .subnav li.active a {
    background: #666;
    color: #fff;
}